import { useState, useEffect } from "react";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";

export const firebaseConfig = {
    apiKey: "AIzaSyDT4JSuIlpWaV2uruFCiSmMwqDAWkWkjEg",
    authDomain: "plut-617e5.firebaseapp.com",
    projectId: "plut-617e5",
    storageBucket: "plut-617e5.appspot.com",
    messagingSenderId: "959971956938",
    appId: "1:959971956938:web:6b6172aada64a4b7aa3c33",
    measurementId: "G-ZEXE052FTC"
  };

  // dashboard giftcard remote config

export const useGiftcardConfig = () => {

  const [giftcardSectionToggle, setGiftcardSectionToggle] = useState(false)

  const [sellGiftcardToggle, setSellGiftcardToggle] = useState(false)
  const [sellGiftCardTitle, setSellGiftCardTitle] = useState('')

  const [seeAllGiftcardToggle, setSeeAllGiftcardToggle] = useState(false)
  const [seeAllGiftcardOverwrite, setSeeAllGiftcardOverwrite] = useState('')

  const [giftcardRatesToggle, setGiftcardRatesToggle] = useState(false)
  const [giftcardRatesOverwrite, setGiftcardRatesOverwrite] = useState('')
  
  const [howToSellGiftcardToggle, setHowToSellGiftcardToggle] = useState(false)
  const [howToSellGiftcardVal, setHowToSellGiftcardVal] = useState('')

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis =300000;

  useEffect(() => {
    (async function () {
      await fetchAndActivate(remoteConfig)
        .then((res) => {

        })
        .catch((err) => {
        });

      const giftcard_section_toggle = getValue(remoteConfig, "giftcard_section_toggle");
      const giftcardSectionToggle = giftcard_section_toggle.asBoolean()

      const sellGiftcard_toogle = getValue(remoteConfig, "sellGiftcard_toogle");
      const sellGiftCardToggle = sellGiftcard_toogle.asBoolean()
      const sellGiftCardTitle = getValue(remoteConfig, "sellGiftCards_overwrite");
      const sellGiftCardTitleVal = sellGiftCardTitle.asString()

      const seeAll_giftcard_toggle = getValue(remoteConfig, "seeAll_giftcard_toggle");
      const seeAllGiftcardToggle = seeAll_giftcard_toggle.asBoolean()
      const seeAll_giftcard_overwrite = getValue(remoteConfig, "seeAll_giftcard_overwrite");
      const seeAllGiftcardOverwrite = seeAll_giftcard_overwrite.asString()

      const giftcardRates_toggle = getValue(remoteConfig, "giftcardRates_toggle");
      const giftcardRatesToggle = giftcardRates_toggle.asBoolean()
      const giftcardRates_overwrite = getValue(remoteConfig, "giftcardRates_overwrite");
      const giftcardRatesOverwrite = giftcardRates_overwrite.asString()

      const howToSellGiftcards_overwrite = getValue(remoteConfig, "howToSellGiftcards_overwrite");
      const howToSellGiftcardVal = howToSellGiftcards_overwrite.asString()
      const howTosellGiftcards_toggle = getValue(remoteConfig, "howTosellGiftcards_toggle");
      const howToSellGiftcardToggle = howTosellGiftcards_toggle.asBoolean()

      setGiftcardSectionToggle(giftcardSectionToggle)

      setSellGiftcardToggle(sellGiftCardToggle)
      setSellGiftCardTitle(sellGiftCardTitleVal)

      setSeeAllGiftcardToggle(seeAllGiftcardToggle)
      setSeeAllGiftcardOverwrite(seeAllGiftcardOverwrite)

      setGiftcardRatesToggle(giftcardRatesToggle)
      setGiftcardRatesOverwrite(giftcardRatesOverwrite)

      setHowToSellGiftcardToggle(howToSellGiftcardToggle)
      setHowToSellGiftcardVal(howToSellGiftcardVal)

    })()
  }, [])

  return {seeAllGiftcardToggle,seeAllGiftcardOverwrite,giftcardRatesToggle,giftcardRatesOverwrite,giftcardSectionToggle, sellGiftcardToggle, sellGiftCardTitle, howToSellGiftcardToggle , howToSellGiftcardVal }
} 

  // dashboard crypto remote config

export const useCryptoConfig = () => {

    const [cryptoSectionToggle, setCryptoSectionToggle] = useState(false)
  
    const [sellcryptoToggle, setSellcryptoToggle] = useState(false)
    const [sellCryptoOverwrite, setSellCryptoOverwrite] = useState('')
  
    const [seeAllCryptoToggle, setSeeAllCryptoToggle] = useState(false)
    const [seeAllCryptoOverwrite, setSeeAllCryptoOverwrite] = useState('')
  
    const [cryptoRatesToggle, setCryptoRatesToggle] = useState(false)
    const [cryptoRatesOverwrite, setCryptoRatesOverwrite] = useState('')
    
    const [howTosellCryptoToggle, setHowTosellCryptoToggle] = useState(false)
    const [howToSellcryptoOverwrite, setHowToSellcryptoOverwrite] = useState('')
  
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    // Initialize Remote Config and get a reference to the service
    const remoteConfig = getRemoteConfig(app);
  
    remoteConfig.settings.minimumFetchIntervalMillis =300000;
  
    useEffect(() => {
      (async function () {
        await fetchAndActivate(remoteConfig)
          .then((res) => {
  
          })
          .catch((err) => {
          });
  
        const crypto_section_toggle = getValue(remoteConfig, "crypto_section_toggle");
        const cryptoSectionToggle = crypto_section_toggle.asBoolean()
  
        const sellCrypto_toggle = getValue(remoteConfig, "sellCrypto_toggle");
        const sellcryptoToggle = sellCrypto_toggle.asBoolean()
        const sellCrypto_overwrite = getValue(remoteConfig, "sellCrypto_overwrite");
        const sellCryptoOverwrite = sellCrypto_overwrite.asString()
  
        const seeAll_crypto_toggle = getValue(remoteConfig, "seeAll_crypto_toggle");
        const seeAllCryptoToggle = seeAll_crypto_toggle.asBoolean()
        const seeAll_crypto_overwrite = getValue(remoteConfig, "seeAll_crypto_overwrite");
        const seeAllCryptoOverwrite = seeAll_crypto_overwrite.asString()
  
        const cryptoRates_toggle = getValue(remoteConfig, "cryptoRates_toggle");
        const cryptoRatesToggle = cryptoRates_toggle.asBoolean()
        const cryptoRates_overwrite = getValue(remoteConfig, "cryptoRates_overwrite");
        const cryptoRatesOverwrite = cryptoRates_overwrite.asString()
  
        const howToSellcrypto_overwrite = getValue(remoteConfig, "howToSellcrypto_overwrite");
        const howToSellcryptoOverwrite = howToSellcrypto_overwrite.asString()
        const howTosellCrypto_toggle = getValue(remoteConfig, "howTosellCrypto_toggle");
        const howTosellCryptoToggle = howTosellCrypto_toggle.asBoolean()
  
        setCryptoSectionToggle(cryptoSectionToggle)
  
        setSellcryptoToggle(sellcryptoToggle)
        setSellCryptoOverwrite(sellCryptoOverwrite)
  
        setSeeAllCryptoToggle(seeAllCryptoToggle)
        setSeeAllCryptoOverwrite(seeAllCryptoOverwrite)
  
        setCryptoRatesToggle(cryptoRatesToggle)
        setCryptoRatesOverwrite(cryptoRatesOverwrite)
  
        setHowTosellCryptoToggle(howTosellCryptoToggle)
        setHowToSellcryptoOverwrite(howToSellcryptoOverwrite)
  
      })()
    }, [])
  
    return {seeAllCryptoToggle,seeAllCryptoOverwrite,cryptoRatesToggle,cryptoRatesOverwrite,cryptoSectionToggle, sellcryptoToggle, sellCryptoOverwrite, howTosellCryptoToggle ,howToSellcryptoOverwrite }
  } 

export const useCarouselConfig = () => {

  const [carouselToggle, setCarouselToggle] = useState(false)

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis =300000;

  useEffect(() => {
    (async function () {
      await fetchAndActivate(remoteConfig)
        .then((res) => {

        })
        .catch((err) => {
        });

      const remoteCarouselToggle = getValue(remoteConfig, "dashboard_carousel_toggle");
      const carouselToggle = remoteCarouselToggle.asBoolean()

      setCarouselToggle(carouselToggle)

    })()
  }, [])

  return {carouselToggle}
} 

export const useModalConfig = () => {
  const [modalPopOver, setModalPopOver] = useState(false)

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis =300000;

  useEffect(() => {
    (async function () {
      await fetchAndActivate(remoteConfig)
        .then((res) => {

        })
        .catch((err) => {
        });

      const remoteWelcomeModalToggle = getValue(remoteConfig, "dashboard_welcome_modal_toggle");
      const WelcomeModalToggle = remoteWelcomeModalToggle.asBoolean()

      setModalPopOver(WelcomeModalToggle)

    })()
  }, [])

  return {modalPopOver}
} 

export const useBannerConfig = () => {
 const [bannerJson, setBannerJson] = useState([])

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  // Initialize Remote Config and get a reference to the service
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis =300000;

  useEffect(() => {
    (async function () {
      await fetchAndActivate(remoteConfig)
        .then((res) => {

        })
        .catch((err) => {
        });
        const remotebanner = getValue(remoteConfig, "banner");
        const banner = remotebanner.asString()
        const bannerJson = JSON.parse(banner)

      setBannerJson(bannerJson)

    })()
  }, [])

  return {bannerJson}
} 
 
export const usePlutLogo = () => {
  const [plutLogo, setPlutLogo] = useState("")
 
   // Initialize Firebase
   const app = initializeApp(firebaseConfig);
   // const analytics = getAnalytics(app);
   // Initialize Remote Config and get a reference to the service
   const remoteConfig = getRemoteConfig(app);
 
   remoteConfig.settings.minimumFetchIntervalMillis =300000;
 
   useEffect(() => {
     (async function () {
       await fetchAndActivate(remoteConfig)
         .then((res) => {
 
         })
         .catch((err) => {
         });
         const remotePlutLogo = getValue(remoteConfig, "plut_logo");
         const plutLogo = remotePlutLogo.asString()
         setPlutLogo(plutLogo)
 
     })()
   }, [])
 
   return {plutLogo}
 } 