export const app = {
  supabase: {
    url: "https://xufqodtnqatswecikbcv.supabase.co",
    apiKey:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh1ZnFvZHRucWF0c3dlY2lrYmN2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzgyNTUxOTksImV4cCI6MTk5MzgzMTE5OX0.R3VI69dtQohJ2OOT3Nxg92Ai_Nj-hVblbMdqoXQtDI4",
  },
  coreApi: {
    coreApiUrl:
      "https://plute-dev-core.agreeablewater-fe9b1d2c.northeurope.azurecontainerapps.io",
  },
};
