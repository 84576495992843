import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useStorage } from "./hooks";
import { Routes as AuthRoutes } from "./components/auth";
import { Routes as UserRoutes } from "./components/user";
// import { BankAccountForm } from "./components/bankaccount/Bankaccount";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit";
import { useState } from "react";

import OneSignal from 'onesignal-cordova-plugin';
import { Capacitor } from '@capacitor/core';

setupIonicReact();

const App: React.FC = () => {
  const { appData, isLoading } = useStorage();
  const [isLoggredIn, setIsLoggedIn] = useState(false);

  // Call this function when your app starts
  function OneSignalInit() {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.Debug.setLogLevel(6);

    // Uncomment to set OneSignal visual logging to VERBOSE  
    // OneSignal.Debug.setAlertLevel(6);

    // NOTE: Update the init value below with your OneSignal AppId.
    OneSignal.init("505e037f-be14-4e99-90dc-437971799f36");


    let myClickListener = async function (event: any) {
      let notificationData = JSON.stringify(event);
      console.log(notificationData, 'notification data');

    };
    OneSignal.Notifications.addEventListener("click", myClickListener);


    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    OneSignal.Notifications.requestPermission(function (accepted: any) {
      console.log("User accepted notifications: " + accepted);
    });
  }

  if (Capacitor.getPlatform() !== 'web') {
    OneSignalInit();
  }

  return !isLoading ? (
    <IonApp>
      {!appData?.user?.token && (
        <IonReactRouter>
          <IonRouterOutlet>
            <AuthRoutes setIsLoggedIn={setIsLoggedIn} />
          </IonRouterOutlet>
        </IonReactRouter>
      )}

      {(appData?.user?.token || isLoggredIn) && (
        <IonReactRouter>
          <IonRouterOutlet id="main-content">
            <UserRoutes />
          </IonRouterOutlet>
        </IonReactRouter>
      )}
    </IonApp>
  ) : (
    <></>
  );
};

export default App;
