import { IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonTabBar, IonTabButton, IonTitle, IonToolbar } from "@ionic/react";
import styles from "./HowToSell.module.css";
import { card, home, personCircle } from "ionicons/icons";

interface Props {
  type: string;
}

export default function HowToSell({ type }: Props) {
  return (<>
    <IonHeader className="ion-no-border">
      <IonToolbar className={styles.ionToolBar}>
        <IonButtons slot="start">
          <IonBackButton className={styles.backButton}></IonBackButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent className={`ion-padding ${styles.content}`}>
      <div className={styles.videoWrapper}>
        <IonTitle className={styles.title}>
          How to sell <span className={styles.headerAccent}>{type}</span>
        </IonTitle>
        <div className={styles.videoDisplayWrapper}>
          <video controls className={styles.video}>
            <source src={`/assets/videos/${type}.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </IonContent>
    <IonTabBar slot="bottom" className={styles.tabBar}>
      <IonTabButton tab="dashboard" href="/dashboard">
        <IonIcon icon={home} class={styles.tabBarIcon} />
        <IonLabel className={styles.label}>Home</IonLabel>
      </IonTabButton>

      <IonTabButton tab="transactions" href="/transactions">
        <IonIcon icon={card} class={styles.tabBarIcon} />
        <IonLabel className={styles.label}>Transactions</IonLabel>
      </IonTabButton>

      <IonTabButton tab="settings" href="/settings">
        <IonIcon icon={personCircle} class={styles.tabBarIcon} />
        <IonLabel className={styles.label}>Settings</IonLabel>
      </IonTabButton>
    </IonTabBar>
  </>

  );
}
